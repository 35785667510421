
import { Component, Prop, PropSync } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class OpPagination extends PwrVue {
  @Prop({ default: () => 5 }) itemsPerPage!: number;
  @Prop({ default: () => true }) animateToTop!: boolean | string;
  @PropSync('page', { default: () => 1 }) pageProp!: number;
  @Prop({ required: true }) length!: number;

  private animate() {
    if (this.isProp(this.animateToTop)) {
      this.$vuetify.goTo('body', {
        duration: 0,
        easing: 'easeInOutCubic'
      });
      this.resetAfkTimer();
    }
  }

  get totalPaginationItemsVisible() {
    const breakPoint = this.$vuetify.breakpoint.name;

    switch (breakPoint) {
      case 'xs':
        return 3;

      case 'sm':
        return 5;

      case 'md':
        return 8;

      case 'lg':
        return 12;

      default:
        return 15;
    }
  }

  get paginationElements(): number {
    if (this.length) {
      return Math.ceil(this.length / this.itemsPerPage);
    }

    return 1;
  }
}
