
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import PwrVue from '@/components/PwrVue';
import { GetterTypes as OffersGetterTypes } from '@/store/offers/types';
import Offer from '@/models/Offer';

const offersNamespace = 'offers';

@Component
export default class OpNoResults extends PwrVue {
  @Prop() show!: boolean | string;

  @Getter(OffersGetterTypes.GET_PAGINATED_ITEMS, { namespace: offersNamespace })
  offersPaginated!: Offer[];

  get isNothingFound(): boolean {
    if (!this.isFetching && !this.isProcessing && this.areDictionariesLoaded) {
      return this.offersPaginated.length === 0;
    }

    return false;
  }
}
