
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes
} from '@/store/offers/types';
import { RoutesNames as OfferRoutesNames } from '@/router/offers/types';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import OpFilter from '@/components/Op/Filters/OpFilter.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import OfferItem from '@/views/Offers/Offers/OfferItem.vue';
import OpOfferPagination from '@/components/Op/Offers/OpOfferPagination.vue';
import OpSearchInput from '@/components/Op/OpSearchInput.vue';
import OpOffersSkeletonLoader from '@/components/Op/Offers/OpOffersSkeletonLoader.vue';
import OpNoResults from '@/components/Op/Offers/OpNoResults.vue';

const offersNamespace = 'offers';

@Component({
  components: {
    OpNoResults,
    OpOffersSkeletonLoader,
    OpSearchInput,
    OpOfferPagination,
    OfferItem,
    OpFilter,
    PwrCardTitle,
    PwrCard,
    PwrBtn
  }
})
export default class PublicJobOffers extends PwrVue {
  @Prop() loading!: boolean;
  @Prop() offersExternal!: Offer[];
  @Prop() title!: string;

  @Action(OffersActionTypes.SEARCH, { namespace: offersNamespace })
  search!: (query: string | undefined) => void;

  @Getter(OffersGetterTypes.GET_PAGINATED_ITEMS, { namespace: offersNamespace })
  offersStore!: Offer[];

  get offers(): Offer[] {
    if (this.offersExternal && this.offersExternal.length) {
      return this.offersExternal;
    }
    return this.offersStore;
  }

  private onOfferSelectEvent(offer: Offer): void {
    this.dispatchOfferView(offer, OfferRoutesNames.VIEW);
  }

  private onQueryChange(query: string) {
    this.search(query);
  }
}
