
import {Component} from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class OpSplitLayout extends PwrVue {
  private mounted(): void {
    this.resetAfkTimer();
  }
}
