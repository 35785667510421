
import { Component, Prop, Watch } from 'vue-property-decorator';
import { debounce, isEmpty } from 'lodash';
import PwrVue from '@/components/PwrVue';
import searchIconFile from '@/assets/pwr/icons/search.svg';

@Component
export default class OpSearchInput extends PwrVue {
  @Prop() block!: boolean | string;

  private query = '';
  private queryTmp = '';
  private debounceSearch = debounce(this.searchDebounceTarget, 500);

  get searchIcon() {
    return searchIconFile;
  }

  private searchDebounceTarget(): void {
    this.resetAfkTimer();
    this.query = this.queryTmp;
  }

  private onSearch(): void {
    if (isEmpty(this.queryTmp)) {
      // This allows to manually delete all characters from search filed
      // and still have results updated
      this.query = this.queryTmp;
    } else {
      this.debounceSearch();
    }
  }

  @Watch('query')
  private onPageChange(): void {
    this.$emit('queryChanged', this.query);
  }
}
