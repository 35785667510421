
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PwrVue from '@/components/PwrVue';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes
} from '@/store/offers/types';
import Offer from '@/models/Offer';
import OpPagination from '@/components/Op/OpPagination.vue';

const offersNamespace = 'offers';
@Component({
  components: { OpPagination }
})
export default class OpOfferPagination extends PwrVue {
  @Prop({ default: () => 5 }) itemsPerPage!: number;

  @Action(OffersActionTypes.CHANGE_PAGE, { namespace: offersNamespace })
  changePage!: (page: number) => void;

  @Action(OffersActionTypes.CHANGE_ITEMS_PER_PAGE, { namespace: offersNamespace })
  changeItemsPage!: (itemsPerPage: number) => void;

  @Getter(OffersGetterTypes.GET_PROCESSED_BY_FILTERS, { namespace: offersNamespace })
  offersProcessedByFilters!: Offer[];

  private mounted(): void {
    this.changeItemsPage(this.itemsPerPage);
  }

  private page = 1;

  @Watch('page')
  private onPageChange(): void {
    this.changePage(this.page);
  }
}
