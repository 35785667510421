
import { Component, Prop } from 'vue-property-decorator';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import OpFilter from '@/components/Op/Filters/OpFilter.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { OpFilter, PwrCardTitle, PwrCard, PwrBtn }
})
export default class OfferItem extends PwrVue {
  @Prop() offer!: Offer;
  @Prop({ default: () => 255 }) descriptionTrimLength!: number;

  private selectOffer(): void {
    this.$emit('select', this.offer);
  }
}
