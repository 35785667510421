
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes
} from '@/store/offers/types';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import OfferFilters from '@/views/Offers/OfferFilters/OfferFilters.vue';
import OpSplitLayout from '@/views/Layouts/OpSplitLayout.vue';
import PublicJobOffers from '@/views/Offers/Offers/PublicJobOffers.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';

const offersNamespace = 'offers';

@Component({
  components: {
    PwrCardTitle,
    PwrCard,
    PublicJobOffers,
    OpSplitLayout,
    OfferFilters,
    PwrBtn
  }
})
export default class OffersView extends PwrVue {
  @Prop({ default: () => ['state', 'attributes'] }) forbiddenFilters!: string[];
  @Prop({ default: () => null }) types!: number[];
  @Prop({ default: () => '' }) title!: string;

  // Offers vuex
  @Action(OffersActionTypes.FETCH_PUBLISHED_OFFERS, { namespace: offersNamespace })
  fetchPublishedOffers!: (types: number[]) => void;

  // Fixes empty list when changing tab
  @Action(OffersActionTypes.CLEAR_SEARCH, { namespace: offersNamespace })
  clearSearch!: () => void;

  @Getter(OffersGetterTypes.GET_OFFERS, { namespace: offersNamespace })
  offers!: Offer[];

  private filtersUpdate = true;

  private async mounted(): Promise<void> {
    this.resetAfkTimer();
    await this.fetchPublishedOffers(this.types);
    await this.clearSearch();
    this.filtersUpdate = false;
  }
}
